import carrefour from "../assets/images/home/carrefour.jpg";
// import expositions from '../assets/images/home/expositions.jpg'
import events from "../assets/images/home/events.jpg";
import cabaneBD from "../assets/images/home/cabane.jpg";
import brusonette from "../assets/images/home/brusonette.jpg";
import residences from "../assets/images/home/residences.jpg";
import research from "../assets/images/home/research.jpg";
import projects from "../assets/images/home/projects.jpg";
import about from "../assets/images/home/about.jpg";
import partners from "../assets/images/home/partners.jpg";
import market from "../assets/images/home/market.jpg";
import rouliBouli from "../assets/images/home/rouli-bouli.jpg";

const categories = [
  {
    id: "carrefour",
    image: carrefour,
    url: "/carrefour-du-village",
    title: "Restaurant Carrefour du Village",
  },
  {
    id: "market",
    image: market,
    url: "https://palpfestival.ch/evenements/grand-marche-terroirs-alpins/",
    title: "Grand Marché des Terroirs Alpins",
    target: "_blank",
  },
  {
    id: "expositions",
    image: rouliBouli,
    url: "/expositions",
    title: "Expositions",
  },
  {
    id: "events-and-workshops",
    image: events,
    url: "/evenements-et-ateliers",
    title: "Evénements et ateliers",
  },
  {
    id: "cabane-bd",
    image: cabaneBD,
    url: "/cabane-a-bd",
    title: "Cabane à BD",
  },
  {
    id: "brusonette",
    image: brusonette,
    url: "/epicerie-la-brusonette",
    title: "Epicerie la Brusonette",
  },
  {
    id: "residences",
    image: residences,
    url: "/residences",
    title: "Résidences",
  },
  {
    id: "research",
    image: research,
    url: "/recherche",
    title: "Recherche",
  },
  {
    id: "projects",
    image: projects,
    url: "/projets",
    title: "Projets",
  },
  {
    id: "about",
    image: about,
    url: "/qui-sommes-nous",
    title: "Qui sommes-nous",
  },
  {
    id: "partners",
    image: partners,
    url: "/partenaires",
    title: "Partenaires",
  },
];

export default categories;
