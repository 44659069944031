const workshopsJune = [
  {
    date: "Samedi 1er – dès 9h00",
    title: "Rouli Bouli",
    info: "Ouverture du parcours de balle sur le chemin pédestre reliant Bruson au Châble, avec une dizaine d'installations créées spécialement pour l'occasion.",
  },
  {
    date: "Samedi 1er – dès 17h00",
    title: "Concert",
    info: "Jakstaff and The Two Old Boys (blues/folk/country/bluegrass)",
  },
  {
    date: "Samedi 15 et Dimanche 16",
    title: "Le Grand Marché des Terroirs Alpins",
    info: "70 stands de Suisse, Italie, France, Slovénie - concerts de yodel, italo disco, chansons - ateliers pour enfants - espace gastronomie - contes - conférences - films - Masterclass autour de cépages alpins, ateliers de dégustations - initiation au raclage - etc.",
  },
];

export default workshopsJune;
