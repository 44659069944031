import {
  Main,
  Container,
  Wrapper,
  LeftColumn,
  Paragraph,
  RightColumn,
  Image,
  Link,
} from "../../globalStyles";
import workshopMai from "../../data/workshops-mai.js";
import workshopJune from "../../data/workshops-june.js";
import workshop from "../../assets/images/workshops/workshop.jpg";
import cardsGame from "../../assets/images/workshops/cards.jpg";
import silentParty from "../../assets/images/workshops/dance.jpg";

const EventsAndWorkshops = () => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Evénements et ateliers</span>
            </h2>
            <h4>Des activités basées sur des savoirs partagés</h4>
            <Paragraph>
              <p>
                Des évènements et ateliers sont proposés pour mettre les
                participants en situation d’expérience sur toutes sortes de
                sujets. Ils sont ouverts à toutes et à tous et plus
                particulièrement aux habitantes et habitants de Bruson.
              </p>
            </Paragraph>
            <Paragraph>
              <h4
                style={{ marginBottom: "1.25rem", textDecoration: "underline" }}
              >
                Mai
              </h4>
              {workshopMai.map((workshop, index) => (
                <div key={index} style={{ marginBottom: "1.25rem" }}>
                  <span style={{ fontStyle: "italic", color: "#ff4d6d" }}>
                    {workshop.date}
                  </span>
                  <h4 style={{ marginBottom: "0rem", fontSize: "1.25rem" }}>
                    {workshop.title}
                  </h4>
                  {workshop.description && <p>{workshop.description}</p>}
                  {workshop.info && <p>{workshop.info}</p>}
                </div>
              ))}
            </Paragraph>
            <Paragraph>
              <h4
                style={{ marginBottom: "1.25rem", textDecoration: "underline" }}
              >
                Juin
              </h4>
              {workshopJune.map((workshop, index) => (
                <div key={index} style={{ marginBottom: "1.25rem" }}>
                  <span style={{ fontStyle: "italic", color: "#ff4d6d" }}>
                    {workshop.date}
                  </span>
                  <h4 style={{ marginBottom: "0rem", fontSize: "1.25rem" }}>
                    {workshop.title}
                  </h4>
                  {workshop.description && <p>{workshop.description}</p>}
                  {workshop.info && <p>{workshop.info}</p>}
                </div>
              ))}
            </Paragraph>
            <Paragraph>
              <p style={{ marginTop: "3rem", marginBottom: "0rem" }}>
                *
                <Link href="mailto:atelier@palpfestival.ch">
                  atelier@palpfestival.ch
                </Link>
              </p>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={workshop} alt="Atelier" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img src={cardsGame} alt="Jeu de cartes" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img src={silentParty} alt="Silent party" />
              <figcaption>© PALP Village</figcaption>
            </Image>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default EventsAndWorkshops;
