const workshopsMai = [
  {
    date: "Vendredi 24 – dès 18h30",
    title: "Concert",
    info: "Jay Follo (Reprises de chansons pop/folk/rock)",
  },
  {
    date: "Samedi 25 – de 10h00 à 11h30",
    title: "Atelier macramé",
    info: "Création de bracelets en macramé. Avec Chandra Bastin, de 6 à 99 ans, sur inscription*, 15 CHF",
  },
];

export default workshopsMai;
